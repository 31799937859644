
.rodal .rodal-fade-enter {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    backdrop-filter: blur(8px); 
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  }